import PropTypes from "prop-types";

import { Title, Section, Container, HTML } from "@gh/shared";

export const Content = ({ data }) => (
  <Section size="md">
    <Container>
      <Title wrapper="h1">{data.title}</Title>
      <HTML content={data.content} />
    </Container>
  </Section>
);

Content.defaultProps = {
  data: {},
};

Content.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};
